/*
-----------------------------
Header
-----------------------------
*/
.main-header {
	.top {
		background-color: $celeste;
		padding: 10px 0 14px;
		color: #fff;
		.location {
			a {
				color: #fff;
				text-decoration: none;
				span {
					font-size: 11px;
				}
				&:hover {
					svg {
						fill: $magenta;
					}
				}
			}
		}
		.social {
			a {
				color: #fff;
				text-decoration: none;
				display: inline-block;
				margin-left: 15px;
				&:hover {
					color: $magenta;
				}
			}
		}
	}
	.main {
		background-color: $azul;
		padding: 30px 0;
		.side {
			.side-toggle {
				text-align: right;
				button {
					color: #fff;
					background-color: $azul;
					border: none;
					font-family: $bold;
					text-transform: uppercase;
					font-size: 14px;
					padding: 0;
					display: inline-flex;
					align-items: center;
					span {
						margin-right: 10px;
					}
				}
			}
			nav {
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					align-items: center;
					li {
						margin-left: 60px;
						&:first-child {
							margin-left: 0;
						}
						a {
							color: #fff;
							text-decoration: none;
							display: block;
							padding: 5px 0;
							transition: color 0.1s linear;
							&:hover {
								color: $amarillo;
							}
						}
					}
				}
			}
		}
		.mobile-nav-cont {
			position: absolute;
			top: calc(100% + 15px);
			right: 0;
			width: 100%;
			z-index: 50;
			background-color: #fff;
			box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
			visibility: hidden;
			opacity: 0;
			transform: translateY(15px);
			transition: all 0.2s ease-out;
			&.is-active {
				visibility: visible;
				opacity: 1;
				transform: translateY(0px);
			}
			.mn-inner {
				padding: 15px 15px 0 15px;
				nav {
					ul {
						list-style-type: none;
						margin: 0;
						padding: 0;
						li {
							display: block;
							a {
								display: block;
								margin: 10px 0;
								text-decoration: none;
								color: $azul;
								&:hover {
									color: $magenta;
								}
							}
						}
					}
				}
				.info {
					display: block;
					width: calc(100% + 30px);
					transform: translateX(-15px);
					background-color: $celeste;
					padding: 15px;
					font-size: 13px;
					margin-top: 20px;
					.location {
						margin-bottom: 15px;
						a {
							color: #fff;
							text-decoration: none;
							span {
								font-size: 11px;
							}
							&:hover {
								svg {
									fill: $magenta;
								}
							}
						}
					}
					.social {
						a {
							color: #fff;
							text-decoration: none;
							display: inline-block;
							margin-right: 15px;
							&:hover {
								color: $magenta;
							}
						}
					}
				}
			}
		}
	}

	@media #{$break-md} {
		.main {
			.side {
				nav {
					ul {
						li {
							margin-left: 30px;
						}
					}
				}
			}
		}
	}
	@media #{$break-sm} {
		.main {
			padding: 20px 0;
			.side {
				nav {
					display: none;
				}
			}
		}
	}
}

/*
-----------------------------
Footer
-----------------------------
*/
.main-footer {
	.main {
		background-color: $gris;
		padding: 60px 0;
		.info {
			a {
				display: inline-block;
				margin-bottom: 15px;
				color: $fontcolor;
				text-decoration: none;
				span {
					font-size: 16px;
				}
				svg {
					fill: $magenta;
				}
				&:hover {
					svg {
						fill: $magenta;
					}
				}
			}
			div {
				&:last-child {
					a {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.bottom {
		background-color: $grisdark;
		padding: 15px 0;
	}
}
