@font-face {
	font-family: "Montserrat Bold";
	src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
		url("../fonts/Montserrat-Bold.woff") format("woff"),
		url("../fonts/Montserrat-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat Regular";
	src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
		url("../fonts/Montserrat-Regular.woff") format("woff"),
		url("../fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham Rounded Bold";
	src: url("../fonts/GothamRnd-Bold.woff2") format("woff2"),
		url("../fonts/GothamRnd-Bold.woff") format("woff"),
		url("../fonts/GothamRnd-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
