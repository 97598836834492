// Fonts
$regular: "Montserrat Regular", sans-serif;
$bold: "Montserrat Bold", sans-serif;
$display: "Gotham Rounded Bold", sans-serif;

// Colores
$fontcolor: #222222;
$azul: #1e4866;
$celeste: #5da6db;
$amarillo: #f9bd09;
$magenta: #e32565;
$gris: #f4f4f4;
$grisdark: #434343;
$negro: #222222;

// Media queries
$break-xl: "(max-width: 1399.98px)";
$break-lg: "(max-width: 1199.98px)";
$break-md: "(max-width: 991.98px)";
$break-sm: "(max-width: 767.98px)";
$break-xs: "(max-width: 575.98px)";
$break-480: "(max-width: 480px)";
$break-320: "(max-width: 320px)";
