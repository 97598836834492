/*
-----------------------------
General
-----------------------------
*/

body {
	font-size: 18px;
	font-family: $regular;
	font-weight: 400;
	overflow-x: hidden;
	color: $fontcolor;
	&.overhidden {
		overflow: hidden;
	}
}

p,
blockquote {
	line-height: 165%;
	strong {
		font-family: $bold;
	}
}

.contw {
	width: calc(100% - 30px);
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;
}

.txt-amarillo {
	color: $amarillo;
}

.txt-celeste {
	color: $celeste;
}

.txt-azul {
	color: $azul;
}

.txt-magenta {
	color: $magenta;
}

.banner-simple {
	position: relative;
	overflow: hidden;
	background-color: $azul;
	.caption {
		min-height: 450px;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 10;
		h1 {
			color: #fff;
			font-family: $display;
			font-size: 48px;
			font-size: clamp(36px, 10vw, 48px);
			text-transform: uppercase;
		}
	}
	.poster {
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background-color: $negro;
			opacity: 0.65;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			mix-blend-mode: multiply;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
		}
	}
	@media #{$break-sm} {
		.caption {
			min-height: 350px;
		}
	}
	@media #{$break-xs} {
		.caption {
			min-height: 300px;
		}
	}
}

.barra-colores {
	position: relative;
	display: flex;
	flex-direction: row;
	div {
		flex: 1;
		height: 20px;
		&.azul {
			background-color: $azul;
		}
		&.amarillo {
			background-color: $amarillo;
		}
		&.celeste {
			background-color: $celeste;
		}
		&.magenta {
			background-color: $magenta;
		}
	}
}

.pic-thumb {
	position: relative;
	.pic {
		width: 100%;
		height: auto;
		position: relative;
		z-index: 5;
	}
	.fig {
		position: absolute;
	}
}

.heading-s1 {
	.title {
		font-family: $display;
		font-size: 36px;
		color: $azul;
		&.s2 {
			font-size: 28px;
		}
	}
}

.btn {
	&.btn-solid {
		font-family: $display;
		font-size: 18px;
		color: #fff;
		background-color: $magenta;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
		border-radius: 50px;
		padding: 0.9em 1.35em;
		transition: all 0.2s cubic-bezier(0.22, 1, 0.36, 1);
		&:hover {
			background-color: darken($magenta, 5%);
			transform: scale(1.025);
		}
	}
}
